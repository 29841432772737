<template>
  <div class="overlay-slide" :class="getClass">
    <div class="overlay-slide__heading">
      <h2 class="overlay-slide__title">
        <slot name="title"> Title </slot>
      </h2>
      <button class="overlay-slide__close" @click="close">Close</button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    side: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    isActive() {
      return this.overlay == this.name
    },
    overlay() {
      return this.$store.state.overlay.current
    },
    getClass() {
      return [
        ...(this.isActive ? ['overlay-slide--active'] : []),
        `overlay-slide--${this.side}`,
      ]
    },
  },
  methods: {
    close() {
      this.$store.commit('overlay/set', '')
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.overlay-slide {
  position: fixed;
  max-width: calc(100vw - 80px);
  width: 500px;
  top: 0;
  height: calc(var(--1vh) * 100);
  transition: all var(--easing-time) var(--easing);
  background: var(--color-background2);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &--right {
    right: -100%;
    border-left: 1px solid var(--foreground);
  }
  &--left {
    left: -100%;
    border-right: 1px solid var(--foreground);
  }

  &--active {
    &.overlay-slide {
      &--right {
        right: 0;
      }
      &--left {
        left: 0;
      }
    }
  }

  &__heading {
    @include cx-uc;
    @include cx-m;
    padding: 16px var(--inset);
    border-bottom: 1px solid var(--foreground);
    display: flex;
    justify-content: space-between;
    vertical-align: baseline;
  }

  &__close {
    @include cx-uc;
  }
}
</style>