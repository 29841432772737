<template>
  <div class="article-card">
    <a :href="article.url" class="article-card__image">
      <ShopifyImage :src="article.image"
    /></a>
    <div class="article-card__header">
      <div class="article-card__date">
        {{ article.published }}
      </div>
      <div class="article-card__title">
        {{ article.title }}
      </div>
    </div>
    <div class="article-card__excerpt">
      <div v-html="article.excerpt"></div>
      <a :href="article.url" class="article-card__link">Read More</a>
    </div>
  </div>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
export default {
  components: { ShopifyImage },
  props: {
    article: Object,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.article-card {
  &__header {
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
  }

  &__date {
    width: 80px;
  }

  &__title {
    @include cx-uc;
    @include cx-xl;
  }

  &__excerpt {
    @include helper-link-underline(1);
  }

  &__image {
    width: 100%;
  }

  &__link {
    margin-top: 16px;
    margin-right: auto;
  }
}
</style>