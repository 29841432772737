export default {
  data() {
    return {
      resizeObserver: null
    }
  },

  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      if (this.onResize) {
        requestAnimationFrame(() => {
          this.onResize(entries[0])
        })
      }
    })
    this.resizeObserver.observe(this.$el)
  },

  destroyed() {
    this.resizeObserver.disconnect()
  }
}
