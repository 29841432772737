<template>
  <a
    class="common-link-submenu"
    :class="{ 'common-link--active': active }"
    :href="url"
    @mouseover="open"
  >
    {{ link.title }}
  </a>
</template>

<script>
export default {
  props: {
    link: Object,
  },
  computed: {
    handle() {
      const path = this.link.url.split('/')
      return path[1] || ''
    },
    active() {
      const path = window.location.pathname
      const submenu = this.$store.state.submenu.current
      let isActive = submenu == this.handle

      // if (this.handle == 'shop' && path.startsWith('/collections'))
      //   isActive = true
      // if (this.handle == 'info' && path == '/pages/care') isActive = true
      // if (this.handle == 'about' && path == '/pages/about') isActive = true

      return isActive
    },
    url() {
      if (this.handle == 'shop') return '/collections/all'
      if (this.handle == 'info') return '/pages/care'
      if (this.handle == 'about') return '/pages/about'
      return '/'
    },
  },
  methods: {
    open() {
      this.$store.commit('submenu/set', this.handle)
    },
  },
  // components: { CommonLinks, CommonLink },
}
</script>