<template>
  <button
    class="overlay-close"
    :class="{ 'overlay-close--active': overlay != '' }"
    @click.prevent="$store.commit('overlay/set', '')"
  ></button>
</template>

<script>
export default {
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
    submenu() {
      return this.$store.state.submenu.current
    },
  },
}
</script>

<style lang="scss">
.overlay-close {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 150;
  pointer-events: none;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    background: var(--background);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: all var(--easing-time) var(--easing);
  }

  &--active {
    &:after {
      opacity: 0.8;
      pointer-events: auto;
    }
  }
}
</style>