<template>
  <section class="article-landing">
    <div class="article-landing__inner">
      <div class="article-landing__image">
        <figure class="article-landing__figure">
          <a class="article-landing__link" :href="article.url">
            <ShopifyImage :src="article.image" ratio="9x12" />
          </a>
        </figure>
      </div>

      <div class="article-landing__text">
        <div class="article-landing__heading">
          <h3 class="article-landing__title">
            <a class="article-landing__link" :href="article.url">
              Sentiments<br />
              with {{ article.title }}
            </a>
          </h3>
          <span class="article-landing__published">
            {{ article.published }}
          </span>

          <div v-if="article.journal.intro" class="article-landing__intro" v-html="article.journal.intro">
          </div>
        </div>

        <div
          v-if="article.imageAlt.indexOf(':') > -1"
          class="article-landing__caption"
        >
          <div class="article-landing__excerpt">
            <p
              class="article-landing__figure__caption"
              v-html="article.imageAlt.replace(':', ':<br />')"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
export default {
  components: { ShopifyImage },
  props: { article: Object },
}
</script>

<style lang="scss">
@import '~/styles/base';

.article-landing {
  margin-bottom: 25px;
  background: #ebe8cf;

  &__inner {
    @include wrapper;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 2fr 14fr 8fr;
    gap: 0 var(--gutter);
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    order: 1;
    grid-column: 3 / 4;
  }

  &__figure {
    width: columns(8);
    max-height: 100vh;
    padding: 40px 0 80px;

    &__caption {
      margin-top: 40px;
    }
  }

  &__text {
    position: static;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: none;
    margin: 0;
    padding-top: 120px;
    grid-column: 2 / 3;
  }

  &__heading {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: columns(2);
    /* padding: 0 var(--gutter) 40px; */
  }

  &__title {
    font-size: 42px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &__published {
    margin-top: 15px;
  }

  &__intro {
    margin-top: 20px;
    max-width: 340px;
    a {
      text-decoration: underline;
    }
  }

  &__excerpt {
    position: relative;
    display: inline-block;
    margin-left: columns(3);
    max-width: 300px;
    margin-bottom: 80px;
    padding: 0 var(--gutter);
    line-height: 1.2;
  }
  @include respond-to(m) {
    &__inner {
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    &__image {
      width: auto;
      padding: 0 25px;
    }

    &__figure {
      width: auto;
      max-height: none;
      padding-bottom: 40px;
    }

    &__heading {
      margin-left: 0;
    }

    &__title {
      font-size: 36px;
    }

    &__excerpt {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
}
</style>