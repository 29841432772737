import '~/styles/theme.scss'
import '~/styles/giftcard.scss'

import Vue from 'vue'
import store from '~/store'

import LayoutDefault from '~/components/layout/LayoutDefault.vue'
import PageError from '~/components/page/PageError.vue'
import PageHome from '~/components/page/PageHome.vue'
import PageCollection from '~/components/page/PageCollection.vue'
import PageProduct from '~/components/page/PageProduct.vue'
import PageBlog from '~/components/page/PageBlog.vue'
import PageArticle from '~/components/page/PageArticle.vue'
import PageCart from '~/components/page/PageCart.vue'
import PageSearch from '~/components/page/PageSearch.vue'
import PageDefault from '~/components/page/PageTemplateDefault.vue'
import PageStockists from '~/components/page/PageTemplateStockists.vue'
import CommonLinks from '~/components/common/CommonLinks.vue'
import StockistSection from '~/components/stockist/StockistSection.vue'

Vue.mixin({
  methods: {
    getKey(...rest) {
      const args = Array.from(rest).join('_')

      return `${args}`
    },
  },
})

Vue.component('CommonLinks', CommonLinks)

let vueInstance;

function initializeVue() {
  if (vueInstance) {
    vueInstance.$destroy(); // Destroy the existing instance
  }

  vueInstance = new Vue({
    el: '#app',
    components: {
      LayoutDefault,

      PageError,
      PageHome,
      PageCollection,
      PageProduct,
      PageBlog,
      PageArticle,
      PageCart,

      PageDefault,
      PageStockists,
      PageSearch,

      StockistSection,
    },
    store,
  });
}

// Initial load
initializeVue();

// Listen for the Shopify section load event
document.addEventListener('shopify:section:load', () => {
  initializeVue(); // Re-initialize Vue
});
