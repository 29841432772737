<template>
  <a
    class="common-link-default a"
    :href="link.url"
    :class="{ 'common-link-default--active': link.active }"
  >
    {{ link.title }}
  </a>
</template>

<script>
export default {
  props: {
    link: Object,
  },
}
</script>