<template>
  <div class="article-journal">
    <ArticleLanding class="article-journal__landing" :article="article" />

    <ArticleRows class="article-journal__rows" :content="article.content" />

    <ArticleRelated class="article-journal__related" :article="article" />

    <section
      v-if="article.journal.footer"
      class="article-journal__footer"
      v-html="article.journal.footer"
    ></section>

    <ArticlePagination :blog="blog" class="article-journal__pagination" />
  </div>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ArticleLanding from './ArticleLanding.vue'
import ArticleRelated from './ArticleRelated.vue'
import ArticlePagination from './ArticlePagination.vue'
import ArticleRows from './ArticleRows.vue'

export default {
  components: {
    ShopifyImage,
    ArticleLanding,
    ArticleRelated,
    ArticlePagination,
    ArticleRows,
  },
  props: { blog: Object, article: Object },
}
</script>

<style lang="scss">
.article-journal {
  &__landing {
  }

  &__rows {
  }

  &__related {
  }

  &__footer {
    font-size: 14px;
    max-width: 700px;
    padding: 0 20px 20px;

    h2 {
      font-size: 24px;
      margin-bottom: 0.3em;
      margin-top: 40px;
    }
  }

  &__pagination {
  }
}
</style>