<template>
  <form class="form-newsletter" method="get" @submit.prevent="submit">
    <div class="form-newsletter__inner">
      <input
        v-model="email"
        class="form-newsletter__input"
        type="text"
        placeholder="Email Address"
        required
      />
      <button class="form-newsletter__button">
        <slot name="button"><SvgArrow /></slot>
      </button>
    </div>
    <div v-if="message !== ''" class="message">{{ message }}</div>
  </form>
</template>

<script>
import SvgArrow from '../svg/SvgArrow.vue'
import { NewsletterSubscribe } from '~/lib/klaviyo'

export default {
  components: { SvgArrow },
  props: {
    listId: {
      type: String,
      default: 'YsxRre',
    },
  },
  data() {
    return {
      email: '',
      message: '',
    }
  },
  methods: {
    async submit() {
      try {
        await NewsletterSubscribe({
          listId: this.listId,
          email: this.email,
        })

        this.message = 'Thanks for signing up!'
        this.email = ''
        this.$emit('submitted')
      } catch (e) {
        console.log(e)
        this.message = 'Please enter a valid email address'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.form-newsletter {
  @include placeholder(var(--color-midgrey));
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__inner {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid var(--foreground);
  }

  &__input {
    @include cx-m;
    @include sans;
    color: var(--color-midgrey);
    flex: 1;
    padding: 6px 0 8px;
  }

  &__button {
    margin-top: auto;
    margin-bottom: 8px;
    svg {
      width: 12px;
    }
  }

  &__message {
    color: var(--color-midgrey);
  }
}
</style>