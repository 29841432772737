<template>
  <span class="shopify-product-price">
    <div
      v-if="variantSalePrice || productSalePrice"
      class="shopify-product-price__sale"
    >
      <ShopifyMoney
        :hide-all-currency="hideAllCurrency"
        class="shopify-product-price_sale__amount"
        :amount="variantSalePrice || productSalePrice"
      />
    </div>

    <ShopifyMoney
      v-show="variantPrice || productPrice"
      class="shopify-product-price__amount shopify-product-price__amount--real"
      :hide-all-currency="hideAllCurrency"
      :amount="variantPrice || productPrice || 0"
    />

    <div
      v-if="isUnavailable"
      class="
        shopify-product-price__message shopify-product-price__message--sold
      "
      v-text="soldOutMessage"
    ></div>

    <div
      v-else-if="isLowInStock"
      class="shopify-product-price__message shopify-product-price__message--low"
      v-text="lowInStockMessage"
    ></div>
  </span>
</template>

<script>
import ProductMixin from '~/mixins/product'
import ShopifyMoney from '~/components/shopify/ShopifyMoney.vue'

export default {
  components: { ShopifyMoney },
  mixins: [ProductMixin],
  props: {
    soldOutMessage: {
      type: String,
      default: 'Sold Out',
    },
    lowInStockMessage: {
      type: String,
      default: 'Last One',
    },
    onSaleMessage: {
      type: String,
      default: 'Sale',
    },
    hideAllCurrency: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.shopify-product-price {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0px 3px;

  &__sale > * {
    text-decoration: line-through;
  }

  &__amount {
    display: inline-flex;
  }

  &__message {
    display: inline-flex;

    &--low {
      /* color: var(--color-concrete); */
      font-style: italic;
    }
  }
}
</style>
