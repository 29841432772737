<template>
  <span class="common-link-blank a"> &nbsp; </span>
</template>

<script>
export default {
  props: {
    link: Object,
  },
}
</script>