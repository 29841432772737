<template>
  <div class="page-blog">
    <BlogLocket v-if="blog.handle == 'digital-locket'" :blog="blog" />
    <BlogJournal v-if="blog.handle == 'journal'" :blog="blog" />
  </div>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
import BlogLocket from '../blog/BlogLocket.vue'
import BlogJournal from '../blog/BlogJournal.vue'

export default {
  components: { CommonPage, BlogLocket, BlogJournal },
  props: {
    blog: Object,
  },
}
</script>

<style lang="scss">
.page-blog {
  padding-top: var(--header-height);
}
</style>