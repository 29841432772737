<template>
  <img class="shopify-image" :src="getUrl" :alt="alt" />
</template>

<script>
import PreloadMixin from '~/mixins/preload'

export default {
  mixins: [PreloadMixin],
  props: {
    src: {},
    ratio: {},
    simple: { default: false },
    noResize: { default: false },
    alt: { default: '' },
  },
  data() {
    return {
      loaded: false,
      width: false,
    }
  },
  computed: {
    getStyle() {
      const width = this.getWidth
      const height = this.getHeight

      return {
        width: `${width}px`,
        height: `${height}px`,
      }
    },
    getWidth() {
      let width = 200

      if (this.width) {
        width = Math.round(Math.ceil((this.width * 2) / 50) * 50)
        if (width > 2000) width = 2000
      }

      return width
    },
    getHeight() {
      let height = ''
      if (this.ratio) {
        const numbers = this.ratio.split('x')
        const ratio = numbers[1] / numbers[0]
        height = Math.floor(this.getWidth * ratio)
      }

      return height
    },
    getSrc() {
      return this.formatUrl(this.src, this.getWidth, this.getHeight)
    },
    getUrl() {
      if (this.simple) {
        return this.formatUrl(this.src, 1200)
      }
      if (!this.loaded)
        return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${this.getWidth} ${this.getHeight}'%3E%3C/svg%3E`
      return this.getSrc
    },
  },
  async mounted() {
    this.loaded = true

    await this.preloadImage(this.getSrc)

    this.$nextTick(() => {
      window.addEventListener('resize', this.updateWidth())
    })
  },
  methods: {
    formatUrl(src, width, height = '') {
      if (this.noResize) return src
      const url = src + '?'
      const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
      let format = `${width}x`

      if (this.ratio) {
        format += `${height}_crop_center`
      }

      return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
    },
    updateWidth() {
      if (this.width) return this.width
      this.width = this.$el.clientWidth
    },
  },
}
</script>

<style lang="scss">
.shopify-image {
  width: 100%;
}
</style>