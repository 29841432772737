<template>
  <select v-model="currentCurrency" @change="changeCurrency" class="common-link-currency">
    <option v-for="currency in currencies" 
            :key="currency" 
            :value="currency"
            :selected="currency === currentCurrency">
      {{ currency }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    currencies: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    currentCurrency: {
      get() {
        return 'NZD'
      },
      set(newCurrency) {
        this.$store.commit('setCurrency', newCurrency)
      }
    }
  },
  methods: {
    changeCurrency() {
      // Trigger any additional actions needed when currency changes
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
  .common-link-currency {
    background-color: transparent;
    border: none;
    outline: none;
  }
</style>
