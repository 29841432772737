<template>
  <div class="stockists__stockist">
    <p>
      {{ stockist.title.toUpperCase() }}
      {{ stockist.city }}
      <a v-if="stockist.url" :href="stockist.url" target="_blank">
        {{ stockist.url.replace('https://', '').replace('http://', '') }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    stockist: Object,
  },
}
</script>

<style lang="scss">
</style>