import { useSlideshowContext } from '../../context/useSlideshowContext';
<template>
  <div class="common-slideshow">
    <div class="common-slideshow__slider" ref="emblaRef">
      <div class="common-slideshow__track">
        <slot name="default"></slot>
      </div>
    </div>

    <slot name="pagination"></slot>
    <button @click.prevent="nextClick" class="common-slideshow__next"></button>
    <button @click.prevent="prevClick" class="common-slideshow__prev"></button>
  </div>
</template>

<script setup lang="ts">
import { useSlideshowContext } from '../../context/useSlideshowContext'

const context = useSlideshowContext({
  config: {
    loop: true,
  },
})
const { emblaRef, emblaApi } = context

const nextClick = () => {
  emblaApi.value?.scrollNext()
}

const prevClick = () => {
  emblaApi.value?.scrollPrev()
}
</script>

<style lang="scss">
.common-slideshow {
  overflow: hidden;

  &__track {
    display: flex;
    > * {
      flex: 0 0 100%;
      min-width: 0;
    }
  }

  &__prev,
  &__next {
    background-position: 95%;
    background-repeat: no-repeat;
    background-size: 25px;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.4s ease;
    width: 50%;
    z-index: 100;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 66.4 42.7' style='enable-background:new 0 0 66.4 42.7' xml:space='preserve'%3E%3Cpath class='st0' d='m45.5 1-2.2 2.2L60 19.9H.8V23H60L43.3 39.7l2.2 2.2 20.4-20.4z'/%3E%3C/svg%3E");

    &:hover {
      opacity: 1;
    }

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  &__prev {
    backface-visibility: hidden;
    left: 0;
    transform: scaleX(-1);
  }

  &__next {
    left: 50%;
  }
}
</style>
