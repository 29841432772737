<template>
  <div class="product-card">
    <div class="product-card__inner">
      <a :href="product.url" class="product-card__link"></a>
      <div class="product-card__image">
        <ShopifyImage class="product-card__mood" :src="getMood" ratio="6x8" />
        <ShopifyImage :src="product.featured_image" ratio="6x8" />
      </div>
      <div class="product-card__caption">
        <h2 class="product-card__title" v-html="getTitle"></h2>
        <ShopifyProductPrice class="product-card__price" :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import ShopifyProductPrice from '../shopify/ShopifyProductPrice.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'

export default {
  components: {
    ShopifyProductPrice,
    ShopifyImage,
  },
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    getTitle() {
      return this.product.title
    },
    getMood() {
      return this.product.images.length > 1 ? this.product.images[1].src : this.product.images[0].src
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.product-card {
  // grid-area: auto / auto / auto / span 8;
  // padding:0 var(--gutter);
  vertical-align: top;
  position: relative;
  margin: 0 0 20px;

  &__inner {
    position: relative;
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__image {
    display: grid;
    position: relative;
    > * {
      grid-column: 1/1;
      grid-row: 1/1;
      width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }

  &__mood {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &__caption {
    @include clearfix();
    @include cx-uc();
    @include cx-m();
    padding-top: 15px;
  }

  &__title {
    span {
      text-transform: none;
    }
  }

  &:hover {
    z-index: 100;
    .product-card__mood {
      opacity: 1;
    }
  }

  @include respond-to(m) {
    z-index: 100;
    &__caption {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
</style>
