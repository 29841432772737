<template>
  <section class="product-gallery">
    <div class="product-gallery__slides">
      <button
        v-for="(image, index) in product.images"
        :key="getKey('product-gallery__slide', index)"
        class="product-gallery__slide"
        @click="$store.commit('overlay/open', 'gallery')"
      >
        <CommonReveal>
          <img
            :ref="`gallery_image_${image.src}`"
            class="product-gallery__image"
            :src="image.src"
            :alt="image.alt"
          />
        </CommonReveal>
      </button>

      <div v-if="product.metafields.video" class="product-gallery__slide">
        <video class="product-gallery__video" controls mute>
          <source :src="product.metafields.video" />
        </video>
      </div>

      <ProductRelated
        v-if="product.metafields.related.length > 0"
        :products="product.metafields.related"
        class="product-gallery__related"
      />

      <div class="product-gallery__back">
        <a href="/collections/all" class="product-gallery__link">Back to Shop</a>
      </div>
    </div>
  </section>
</template>

<script>
import CommonReveal from '../Common/CommonReveal.vue'
import ProductRelated from './ProductRelated.vue'
export default {
  components: { CommonReveal, ProductRelated },
  props: {
    product: Object,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.product-gallery {
  margin-top: calc(var(--header-height) + 200px);
  margin-right: calc(-1 * (var(--inset)));

  &__slide {
    + .product-gallery__slide {
      margin-top: 10px;
    }
  }

  &__image {
    width: 100%;
    cursor: pointer;
  }

  &__video {
    width: 100%;
    height: auto;
    display: block;
  }

  &__back {
    border-top: 1px solid var(--color-dark);
    height: 45px;
    padding: 10px 20px;
    position: relative;
    text-align: right;
    // margin-top: 20px;
    /* width: calc(100% + 20px); */
  }

  &__link {
    @include cx-uc;
    @include cx-l;
  }
}
</style>
