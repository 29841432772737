<template>
  <div class="product-slideshow">
    <CommonSlideshow>
      <div v-for="(slide, index) in getSlides" :key="index">
        <CommonSlideImage :slide="slide" />
      </div>
    </CommonSlideshow>
  </div>
</template>

<script>
import CommonSlideshow from '../common/CommonSlideshow.vue'
import CommonSlideImage from '../common/CommonSlideImage.vue'

export default {
  components: {
    CommonSlideshow,
    CommonSlideImage,
  },
  props: {
    product: Object,
  },
  computed: {
    getSlides() {
      return this.product.images
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';
.product-slideshow {
  .global-slideshow {
    &__controls {
      @include respond-to('m') {
        margin-top: -60px;
        z-index: 100;
        padding-bottom: 40px;
        position: relative;
      }
    }
  }
}
</style>
