

<template>
  <TransitionHeight>
    <div class="transition-height-wrap" v-show="active">
      <slot />
    </div>
  </TransitionHeight>
</template>

<script>
import TransitionHeight from './TransitionHeight.vue'
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  components: { TransitionHeight },
}
</script>