<template>
  <div class="page-index">
    <HomeSections :sections="sections" />
  </div>
</template>

<script>
import HomeSections from '~/components/home/HomeSections.vue'

export default {
  components: {
    HomeSections,
  },
  props: {
    sections: {
      type: Array,
    },
  },
  mounted() {
    console.log('PageHome mounted')
    document.addEventListener('shopify:section:load', this.onSectionLoad);
  },
  methods: {
    onSectionLoad(event) {
      console.log('onSectionLoad', event)
    },
  },
}
</script>

<style lang="scss">
.page-index {
  /* padding-top: var(--header-height); */
}
</style>
