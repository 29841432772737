<template>
  <div class="home-landing-single">
    <HomeSectionLandingMedia
      class="home-landing-single__column"
      :image-src="image1Src"
      :video-src="video1Sources"
      :text="section.text_1"
      :link="section.link_1"
    />
  </div>
</template>

<script>
import HomeSectionLandingMedia from "./HomeSectionLandingMedia.vue";

export default {
  components: { HomeSectionLandingMedia },

  computed: {
    image1Src() {
      const src = this.section.image_1?.preview_image?.src || this.section.image_1 || '';
      return src;
    },
    video1Sources() {
      const sources = this.section.video_1?.sources || [];
      return sources;
    },
  },

  props: {
    section: {
      type: Object,
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.home-landing-single {
  margin-bottom: 20px;
  height: calc(var(--1vh) * 100);
  overflow: hidden;
}
</style>
