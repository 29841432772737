<template>
  <div class="app" :style="getAppStyle">
    <TheHeader class="app__header" @resize="onHeaderResize" :currencies="this.currencies" />

    <section class="app__container">
      <slot />
    </section>

    <TheFooter class="app__footer" />

    <OverlayCart />
    <OverlayMmenu />
    <OverlayNewsletter />

    <OverlayClose />
    <OverlayGrid />
  </div>
</template>

<script>
import TheHeader from '../TheHeader.vue'
import TheFooter from '../TheFooter.vue'
import OverlayCart from '../overlay/OverlayCart.vue'
import OverlayMmenu from '../overlay/OverlayMmenu.vue'
import OverlayBanner from '../overlay/OverlayBanner.vue'
import OverlayClose from '../overlay/OverlayClose.vue'
import OverlayGrid from '../overlay/OverlayGrid.vue'
import OverlayNewsletter from '../overlay/OverlayNewsletter.vue'

export default {
  components: {
    TheHeader,
    TheFooter,
    OverlayCart,
    OverlayMmenu,
    OverlayBanner,
    OverlayClose,
    OverlayGrid,
    OverlayNewsletter,
  },
  props: {
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headerHeight: 0,
      vh: '1vh',
    }
  },
  computed: {
    getAppStyle() {
      return {
        '--1vh': `${this.vh}`,
        '--header-height': `${this.headerHeight}px`,
        '--background': `var(--color-${this.backgroundColor})`,
      }
    },
    backgroundColor() {
      console.log(this.$store)
      return this.$store.state.app.backgroundColor
    },
  },
  mounted() {
    this.$store.dispatch('cart/load')

    window.addEventListener('resize', this.onWindowResize)
    this.$nextTick(() => this.onWindowResize())
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onHeaderResize(value) {
      this.headerHeight = value.height
    },
    onWindowResize() {
      if (!this.$el) return
      this.vh = `${window.innerHeight * 0.01}px`
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

@import '~/styles/utils/reset.scss';
@import '~/styles/utils/normalise.scss';

@import '~/styles/frontend/helpers.scss';
@import '~/styles/frontend/transitions.scss';
@import '~/styles/frontend/typography.scss';
@import '~/styles/frontend/fonts.scss';

// Lockets
@import '~/styles/locket/article.scss';
@import '~/styles/locket/blog.scss';

:root {
  --1vh: 1vh;
  --gutter: 16px;
  --columns: 24;
  --inset: 20px;
  --scroll-inset: 15px;

  --wrapper: calc(100vw - (var(--inset) * 2) - var(--scroll-inset));

  --column: calc(
    (1 / var(--columns)) *
      (100vw - (var(--inset) * 2) - var(--scroll-inset) - ((var(--columns) - 1) * var(--gutter))) +
      var(--gutter)
  );

  --color-transparent: transparent;
  --color-light: #fff;
  --color-lightgrey: #b3b3b3;
  --color-midgrey: #6d6d6d;
  --color-darkgrey: #424244;
  --color-dark: #010101;
  --color-accent: #e2d4a3;
  --color-background: #fefdfc;
  --color-background2: #f5f4e7;
  --color-overlay-close: rgba(255, 255, 255, 0.8);

  --background: var(--color-background);
  --foreground: var(--color-dark);

  --font-home-l: 2.3vw;
  --font-lh-home-l: 1.3em;

  --font-home-s: 12px;
  --font-lh-home-s: 1.4em;

  --font-xxl: 48px;
  --font-lh-xxl: 50px;
  --font-xl: 24px;
  --font-lh-xl: 30px;
  --font-l: 18px;
  --font-lh-l: 24px;
  --font-m: 12px;
  --font-lh-m: 16px;
  --font-s: 11px;
  --font-lh-s: 15px;
  --font-xs: 10px;
  --font-lh-xs: 14px;
  --font-xxs: 9px;
  --font-lh-xxs: 14px;

  --easing: cubic-bezier(1, 0, 0, 1);
  --easing-time: 0.8s;

  @include respond-to('xl') {
    --gutter: 12px;
  }

  @include respond-to('m') {
    --gutter: 4px;
    --scroll-inset: 0px;

    --font-xxl: 26px;
    --font-lh-xxl: 30px;
    --font-xl: 18px;
    --font-lh-xl: 24px;
    --font-l: 18px;
    --font-lh-l: 24px;
    --font-m: 12px;
    --font-lh-m: 16px;
    --font-s: 11px;
    --font-lh-s: 15px;
  }
}

// Main
html,
body {
  margin: 0;
  padding: 0;
  position: relative;
}

.app {
  transition: background-color var(--easing-time) var(--easing);
  background: var(--background);
  min-height: calc(var(--1vh) * 100);
  display: flex;
  flex-direction: column;

  color: var(--foreground);

  a,
  input,
  select,
  button {
    color: var(--foreground);
  }

  .svg-logo {
    fill: var(--foreground);
  }

  &__container {
    position: relative;
    z-index: 10;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__footer {
  }
}

body {
  @include cx;
  @include cx-s;
  @include sans;
  background: var(--background);
}

.a,
a {
  display: inline-flex;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
}

input,
select textarea,
button,
a.button {
  @include cx;
  @include sans;
  @include appearance-none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: inline-flex;
}

button,
a.button {
  display: inline-flex;
  vertical-align: baseline;
}

legend {
  width: 100%;
}

:focus {
  outline: none;
  outline-width: 0px;
  outline-style: solid;
  outline-color: #eee;
}
</style>
