<template>
  <div class="cart__item__quantity">
    <template v-if="isLoading">
      <svg class="spinner" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12a9 9 0 1 1-6.219-8.56"></path></svg>
    </template>
    <template v-else>
      <button class="cart__item__decrement" @click.prevent="decrement">
        {{ decrementSymbol }}
      </button>

      <div class="cart__item__quantity__count">
        {{ quantity }}
      </div>

      <button class="cart__item__increment" @click.prevent="increment">
        {{ incrementSymbol }}
      </button>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    incrementSymbol: {
      type: String,
      default: '+',
    },
    decrementSymbol: {
      type: String,
      default: '−',
    },
    item: {
      type: Object,
    },
  },
  computed: {
    quantity() {
      return this.item.quantity
    },
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async increment() {
      this.isLoading = true
      try {
        await this.update(this.quantity + 1)
      } finally {
        this.isLoading = false
      }
    },
    async decrement() {
      this.isLoading = true
      try {
        await this.update(this.quantity - 1 < 0 ? 0 : this.quantity - 1)
      } finally {
        this.isLoading = false
      }
    },
    async update(quantity) {
      return await this.$store.dispatch('cart/update', {
        id: this.item.id,
        quantity,
      })
    },
  },
}
</script>
<style lang="scss">
@import '~/styles/base';

.cart__item__quantity {
  display: flex;
  gap: 10px;
}

.spinner {
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
