<template>
  <div class="shopify-money">
    <div class="shopify-money__symbol">{{ getMoneySymbol }}</div>
    <div class="shopify-money__amount">{{ getMoneyAmount(amount) }}</div>
    <div v-if="getMoneyCodeDisplay && !hideAllCurrency" class="shopify-money__code">
      {{ getMoneyCodeDisplay }}
    </div>
  </div>
</template>

<script>
import MoneyMixin from '~/mixins/money'

export default {
  mixins: [MoneyMixin],
  props: {
    hideDoubleZeros: {
      type: Boolean,
      default: true,
    },
    showDefaultCurrencyCode: {
      type: Boolean,
      default: true,
    },
    hideAllCurrency: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss">
.shopify-money {
  display: inline-flex;
  &__code {
    margin-left: 2px;
  }
}
</style>
