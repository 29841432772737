import { provide, inject } from 'vue'
import emblaCarouselVue, { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel-vue'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

export type SlideshowContextProps = {
  config?: EmblaOptionsType
  thumbnailConfig?: EmblaOptionsType
}

export type SlideshowContextType = {
  emblaRef?: any
  emblaApi?: EmblaCarouselType
  emblaThumbnailRef?: any
  emblaThumbnailApi?: EmblaCarouselType
}

export const SLIDESHOW_INJECTION_KEY = Symbol('SLIDESHOW')

export const useSlideshowContext = (options?: SlideshowContextProps) => {
  const config = options?.config
  const thumbnailConfig = options?.thumbnailConfig

  const [emblaRef, emblaApi] = emblaCarouselVue(
    {
      loop: false,
      ...config,
    },
    [WheelGesturesPlugin()]
  )
  const [emblaThumbnailRef, emblaThumbnailApi] = emblaCarouselVue(
    {
      loop: false,
      ...thumbnailConfig,
    },
    [WheelGesturesPlugin()]
  )

  const values = {
    emblaRef,
    emblaApi,
    emblaThumbnailRef,
    emblaThumbnailApi,
  }

  provide<SlideshowContextType>(SLIDESHOW_INJECTION_KEY, values)
  return values
}

export const useSlideshowInject = () => {
  const context = inject<SlideshowContextType>(SLIDESHOW_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
