<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    delay: {
      type: [Number, String],
      default: '0',
    },
  },
  data() {
    return {
      intersecting: false,
      active: false,
      timeout: false,
      observer: null,
    }
  },
  watch: {
    intersecting(isIntersecting) {
      if (this.timeout) clearTimeout(this.timeout)

      if (isIntersecting) {
        this.timeout = setTimeout(
          () => (this.active = true),
          parseInt(this.delay)
        )
      } else {
        this.active = false
      }
    },
    active: {
      // immediate: true,
      handler(active) {
        this.$emit('intersect', active)
      },
    },
  },
  mounted() {
    this.observer = this.startObserver(this.$el)
  },
  destroyed() {
    this.observer.disconnect()
  },
  methods: {
    startObserver(section, key) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (entry.isIntersecting) this.intersecting = true
        },
        {
          threshold: [0, 1],
        }
      )

      observer.observe(section)
      return observer
    },
  },
}
</script>

<style lang="scss">
</style>