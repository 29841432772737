<template>
  <CommonPage class="page-error">
    <section class="page-error__heading">
      <h1>Not Found</h1>
    </section>

    <section class="page-error__content">
      <p>
        Sorry we can't find that page,
        <a href="/collections/all">visit the shop here</a>
      </p>
    </section>
  </CommonPage>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
export default {
  components: { CommonPage },
  mounted() {
    this.$store.commit('app/setBackground', 'background2')
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-error {
  background: var(--color-background2);

  &__heading {
    @include cx-xl;
    @include cx-uc;
    padding-bottom: 100px;
    padding-top: 120px;
  }

  &__content {
    @include helper-link-underline(1);
  }
}
</style>