<template>
  <div class="home-sections">
    <component
      :is="getComponentType(section)"
      v-for="(section, k) in sections"
      :key="'home_component_' + k"
      :section="section"
    ></component>
  </div>
</template>

<script>
import SectionImageText from './HomeSectionImageText.vue'
import SectionCollection from './HomeSectionCollection.vue'
import HomeSectionLandingSingle from './HomeSectionLandingSingle.vue'
import HomeSectionLandingDouble from './HomeSectionLandingDouble.vue'

export default {
  props: {
    sections: {
      type: Array,
    },
  },
  methods: {
    getComponentType(section) {
      if (section.type == 'landing_single') return 'HomeSectionLandingSingle'
      if (section.type == 'landing_double') return 'HomeSectionLandingDouble'
      if (section.type == 'text_image') return 'SectionImageText'
      if (section.type == 'collection') return 'SectionCollection'

      return false
    },
  },
  components: {
    SectionImageText,
    SectionCollection,
    HomeSectionLandingSingle,
    HomeSectionLandingDouble,
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.home-sections {
  display: grid;
  grid-row-gap: 150px;
  @include cx-home-s();
  margin-bottom: 300px;
}
</style>
