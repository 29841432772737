<template>
  <div class="page-image">
    <ShopifyAccentuateImage
      class="page-image__image"
      :src="page.metafields.image"
    />
    <div class="page-image__decorator"></div>
  </div>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
import ShopifyAccentuateImage from '../shopify/ShopifyAccentuateImage.vue'
export default {
  components: { CommonPage, ShopifyAccentuateImage },
  props: {
    page: Object,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-image {
  position: relative;
  flex: 1;
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    /* height: 50%; */
    /* width: 50% !important; */
    height: 100%;
    object-position: center;
    object-fit: cover;
    mix-blend-mode: multiply;

    @include respond-to('m') {
      width: 100% !important;
    }

    @include respond-to('xs') {
      position: static;
      height: auto;
    }
  }

  /* &__decorator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: calc(100%);
    background: var(--color-accent);

    @include respond-to('xs') {
      display: none;
    }
  } */
}
</style>