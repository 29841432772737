<template>
  <header
    class="the-header"
    :style="getStyle"
    @mouseleave="$store.commit('submenu/set', '')"
  >
    <HeaderDesktop
      class="the-header the-header--desktop"
      @resize="(v) => $emit('resize', v)"
      :currencies="currencies"
    />
    <HeaderMobile
      class="the-header the-header--mobile"
      @resize="(v) => $emit('resize', v)"
      :currencies="currencies"
    />
    <div
      class="the-header__overlay"
      :class="submenuOpen ? `the-header__overlay--active` : ''"
    ></div>
  </header>
</template>

<script>
import HeaderDesktop from './header/HeaderDesktop.vue'
import HeaderMobile from './header/HeaderMobile.vue'
import BrowserMixin from '~/mixins/browser'

export default {
  components: { HeaderDesktop, HeaderMobile },
  mixins: [BrowserMixin],
  props: {
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      invert: false,
    }
  },
  computed: {
    getStyle() {
      const foreground = !this.submenuOpen && this.invert ? 'light' : 'dark'
      const background =
        !this.submenuOpen && this.invert ? 'transparent' : 'background'

      return `
      --foreground: var(--color-${foreground});
      --background: var(--color-${background});
    `
    },
    submenuOpen() {
      return this.$store.state.submenu.current != ''
    },
    isHome() {
      return this.$store.state.shopify.template == 'index'
    },
  },
  watch: {
    scrollTop(v) {
      this.onScroll()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onScroll()
    })
  },
  methods: {
    onScroll() {
      if (!this.isHome) return
      const isInverted = this.resizeHeight > this.scrollTop
      if (this.invert != isInverted) this.invert = isInverted
    },
  },
}
</script>


<style lang="scss">
@import '~/styles/base';

.the-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  &--desktop {
    @include respond-to('m') {
      display: none;
    }
  }
  &--mobile {
    @include respond-to('m+') {
      display: none;
    }
  }

  &__overlay {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 150;
    pointer-events: none;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      background: var(--background);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
    }

    &--active {
      &:after {
        opacity: 0.8;
      }
    }
  }
}
</style>