<template>
  <div class="newsletter">
    <div class="note">{{ note }}</div>
    <form class="form" method="get" @submit.prevent="submit">
      <input v-model="email" type="text" placeholder="Email Address" required />
      <button>
        <SvgArrow />
      </button>
    </form>
    <div v-if="message !== ''" class="message">{{ message }}</div>
  </div>
</template>

<script>
import SvgArrow from '../svg/SvgArrow.vue'
import { NewsletterSubscribe } from '~/lib/klaviyo'

export default {
  components: { SvgArrow },
  props: {
    note: {
      type: String,
      default: '',
    },
    listId: {
      type: String,
      default: 'YsxRre',
    },
  },
  data() {
    return {
      email: '',
      message: '',
    }
  },
  methods: {
    async submit() {
      try {
        await NewsletterSubscribe({
          listId: this.listId,
          email: this.email,
        })

        this.message = 'Thanks for signing up!'
        this.email = ''
        this.$emit('submitted')
      } catch (e) {
        console.log(e)
        this.message = 'Please enter a valid email address'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.newsletter {
  padding: 0 var(--gutter);
  display: flex;
  gap: 10px;

  @include respond-to('s') {
    padding: 0;
    flex-direction: column;
  }

  .note {
    color: var(--foreground);
    padding-right: 30px;

    @include respond-to('s') {
      padding: 0 0 24px 0;
    }
  }

  .message {
    color: var(--color-midgrey);
    padding-left: 20px;
    max-width: 40%;

    @include respond-to(s) {
      max-width: 100%;
    }
  }

  .form {
    @include placeholder(var(--color-midgrey));
    display: flex;
    align-items: baseline;

    input {
      width: 120px;
      @include sans();
      @include cx-s();
      color: var(--color-midgrey);
      padding-bottom: 1px;

      @include respond-to('s') {
        width: 100%;
      }
    }

    button {
      svg {
        width: 10px;
      }
    }
  }
}
</style>