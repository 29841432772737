export default {
  props: {
    showZeroCents: {
      type: Boolean,
      default: false,
    },
    showRounded: {
      type: Boolean,
      default: true,
    },
    showDefaultCurrencyCode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getMoneySymbol() {
      return '$'
    },
    getMoneyCodeDisplay() {
      return window.Shopify.currency.active
    },
  },
  methods: {
    getMoneyAmount(sourceAmount) {
      const {
        currentCurrency,
        // showZeroCents,
      } = this

      // Default amount
      let amount = sourceAmount

      // Some currencies have no cents
      if (currentCurrency == 'JPY' || currentCurrency == 'KRW') amount = parseFloat(amount).toFixed(0)
      else amount = parseFloat(amount).toFixed(2)

      //Hide double zeroes by default
      if (!this.showZeroCents) amount = amount.replace('.00', '')

      return amount
    },
  },
}
