

<template>
  <div class="page-article">
    <ArticleJournal
      v-if="blog.handle == 'journal'"
      :blog="blog"
      :article="article"
    />

    <ArticleLocket
      v-if="blog.handle == 'digital-locket'"
      :blog="blog"
      :article="article"
    />
  </div>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
import ArticleJournal from '../article/ArticleJournal.vue'
import ArticleLocket from '../article/ArticleLocket.vue'
export default {
  components: { CommonPage, ArticleJournal, ArticleLocket },
  props: {
    blog: Object,
    article: Object,
  },
}
</script>