<template>
  <section class="stockist-section">
    <div class="stockist-section__inner">
      <div class="stockist-section__region">
        <div class="stockist-section__heading">
          <h2>New Zealand</h2>
        </div>
        <StockistCards filter="New Zealand" :stockists="blocks" />
      </div>

      <div class="stockist-section__region">
        <div class="stockist-section__heading">
          <h2>Australia</h2>
        </div>
        <StockistCards filter="Australia" :stockists="blocks" />
      </div>

      <div class="stockist-section__region">
        <div class="stockist-section__heading">
          <h2>International</h2>
        </div>
        <StockistCards filter="International" :stockists="blocks" />
      </div>
    </div>
  </section>
</template>

<script>
import StockistCards from '../stockist/StockistCards.vue'
export default {
  components: { StockistCards },
  props: {
    blocks: Array,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.stockist-section {
  @include clearfix();
  padding: 0 var(--inset) 80px;

  &__inner {
    @include cx-m();
    @include offset(4);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gutter);
    grid-auto-flow: row dense;

    @include respond-to('m') {
      @include offset(1);
    }

    @include respond-to('xxs') {
      grid-template-columns: 1fr;
    }
  }

  &__region {
    display: block;
    padding: 0 calcPercentage(10, 3) 20px 0;
    width: 100%;

    @include respond-to('m') {
      padding: 0 0 20px 0;
    }

    &:nth-child(1) {
      grid-row: auto / span 2;
    }
    &:nth-child(2),
    &:nth-child(3) {
      grid-column: 2 / -1;
      @include respond-to('xxs') {
        grid-column: 1 / -1;
      }
    }
  }

  &__heading {
    @include cx-uc();
    @include cx-l();
    margin-bottom: 20px;
  }

  &__stockists {
    @include cx-full();
    padding: 0 var(--gutter);
  }
}
</style>