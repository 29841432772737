import axios from 'axios';
import stripJsonComments from 'strip-json-comments';
import _ from 'lodash';

async function getRecaptchaToken(formType) {
  return new Promise((res,rej) => {
    try {
      window.grecaptcha.ready(function() {
        window.grecaptcha
          .execute(
            window.Shopify.recaptchaV3.siteKey, 
            {action: formType}
          )
          .then((token) => res(token))
      });
    } catch(e) {
      rej(e)
    }
  })
}

const axiosGetSettings = {
  method: 'GET',
  dataType: 'json',
  // contentType: 'application/json',
}

const axiosPostSettings = {
  method: 'POST',
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    'Content-Type': 'application/json'
  }
}

const AxiosGet = async (url, options = {}) => {
  const response = (await axios.request({
    ...axiosGetSettings,
    ...options,
    url,
  }));

  if(options.raw) return response;

  return (typeof (response.data) == 'string') 
    ? JSON.parse(stripJsonComments(response.data)) 
    : response.data;

}

const AxiosPost = async (url, { data }, options = {}) => {
  const response = (await axios.request({
    ...axiosPostSettings,
    ...options,
    data,
    url
  }));

  return response.data;
}




export async function formatParams(url, data, formType, _method = false) {
  const params = {
    utf8: '✓',
    form_type: formType,
  }

  if (_method) {
    params._method = _method
  }

  // Add attributes
  for (const key in data) {
    if ({}.hasOwnProperty.call(data, key)) {
      if (!!data[key] && data[key] !== '') {
        params[key] = data[key]
      }
    }
  }

  // Add token to submission
  const token = await getRecaptchaToken(formType);
  params['recaptcha-v3-token'] = token;

  return params
}

const submitRequest = function (path, params, _method = false) {
  const method = "post";

  const form = new FormData();

  form.append("method", method);
  form.append("action", path);

  //Add form decoration
  if (_method) form.append('_method', _method);

  //Add attributes
  for (let key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (params[key] && params[key] != '') form.append(key, params[key]);
    }
  }

  return axios({
    method: "POST",
    url: path,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const AxiosForm = async function (url, data) {
  const response = await submitRequest(url, data);

  console.log(response);

  if (
    response.request.responseURL.indexOf('/challenge') > -1
  ) {
    console.log('go to challenge url');
    window.location.href = '/challenge';
  }

  return response.data;
}

export { AxiosGet, AxiosPost, AxiosForm }
