<template>
  <div class="home-section" :class="getSectionClass">
    <div class="home-section__details">
      <div class="home-section__title" v-html="getSectionTitle"></div>

      <div class="home-section__link" v-html="section.link"></div>
    </div>
    <div class="home-section__image">
      <img :src="getImage" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
    },
  },
  computed: {
    getImage() {
      return this.section?.image?.src  || this.section.image
    },
    getSectionClass() {
      return `home-section--${this.section.style}`
    },
    getSectionTitle() {
      return `<span class="home-section__label" >${this.section.label}</span> ${this.section.caption}`
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.home-section {
  display: grid;
  /* --column-count: 24; */
  grid-template-columns: repeat(var(--column-count), 1fr);
  grid-gap: calc(var(--gutter) * 2);

  padding: 0 var(--gutter);
  margin: 0 var(--inset);

  &__details {
    padding-top: 150px;

    @include respond-to(l) {
      padding-top: 60px;
    }

    @include respond-to(s) {
      padding-top: 0;
    }
  }

  &__title {
    @include cx-home-l();
    @include cx-uc();
    padding-bottom: 40px;

    @include respond-to(l) {
      font-size: 24px;
    }
  }

  &__label {
    @include cx-home-s();
    @include cx-uc();
    display: inline-block;
    padding-right: 40px;
    padding-bottom: 0;
    margin-bottom: -4px;
    float: left;

    @include respond-to(l) {
      float: none;
      margin-bottom: 20px;
    }
  }

  &__link {
    @include helper-link-underline(true);
  }

  &--large-image-left .home-section {
    &__image {
      grid-area: 1 / 1 / auto / span 12;

      @include respond-to('s') {
        grid-area: 1 / 1 / auto / -1;
      }
    }

    &__details {
      grid-area: 1 / span 8 / auto / -3;

      @include respond-to('l') {
        grid-area: 1 / span 10 / auto / -1;
      }

      @include respond-to('s') {
        grid-area: 2 / 1 / auto / -1;
      }
    }
  }

  &--medium-image-left .home-section {
    &__image {
      grid-area: 1 / 1 / auto / span 8;

      @include respond-to('s') {
        grid-area: 1 / 1 / auto / -1;
        padding-bottom: 100px;
      }
    }

    &__details {
      grid-area: 1 / span 8 / auto / -1;

      @include respond-to('l') {
        grid-area: 1 / span 10 / auto / -1;
      }

      @include respond-to('s') {
        grid-area: 2 / 1 / auto / -1;
      }
    }
  }

  &--medium-image-right .home-section {
    &__image {
      grid-area: 1 / span 8 / auto / -1;

      @include respond-to('s') {
        grid-area: 1 / 3 / auto / -3;
        padding-bottom: 100px;
      }
    }

    &__details {
      grid-area: 1 / 1 / auto / span 8;

      @include respond-to('l') {
        grid-area: 1 / 3 / auto / span 10;
      }

      @include respond-to('s') {
        grid-area: 2 / 1 / auto / -1;
      }
    }
  }

  &--large-image-right .home-section {
    &__details {
      grid-area: 1 / 3 / auto / span 8;

      @include respond-to('l') {
        grid-area: 1 / 1 / auto / span 10;
      }

      @include respond-to('s') {
        grid-area: 1 / 1 / auto / -1;
      }
    }

    &__image {
      grid-area: 1 / span 12 / auto / -1;

      @include respond-to('s') {
        display: none;
      }
    }
  }
}
</style> 