<template>
  <button
    class="common-link-cart"
    @click.prevent="$store.commit('overlay/set', 'cart')"
  >
    Cart (<span v-text="getItemCount"></span>)
  </button>
</template>

<script>
export default {
  props: {
    link: Object,
  },
  computed: {
    getItemCount() {
      return this.$store.getters['cart/getItemCount']
    },
  },
}
</script>