
<template>
  <div v-if="cart" class="page-cart">
    <section class="heading">
      <div class="title">
        <h1>Cart ({{ count }})</h1>
      </div>
    </section>

    <div class="items">
      <div v-if="cart.items.length > 0" class="items-inner">
        <div v-for="item in cart.items" :key="item.id" class="item">
          <div class="item-inner">
            <div class="item-image">
              <a :href="item.url">
                <ShopifyImage ratio="6x8" :src="item.image" />
              </a>
            </div>
            <div class="item-title">
              <a :href="item.url">
                {{ item.title }}
              </a>
            </div>
            <div class="item-price">
              <ShopifyMoney :amount="item.price / 100" /> x
              {{ item.quantity }}
            </div>
            <div class="item-controls">
              <span class="a" @click="() => remove(item)">Remove</span>
            </div>
          </div>
          <div class="item-decorator"></div>
        </div>
      </div>
      <div v-else class="empty">
        <div class="empty-inner">
          <p>You don't have anything in your cart yet.</p>
        </div>
      </div>
    </div>
    <div class="controls">
      <div class="controls-inner">
        <span v-if="cart.items.length > 0" class="total">
          Total
          <ShopifyMoney :amount="cart.total_price / 100" />
        </span>
        <a v-if="cart.items.length > 0" class="next" href="/checkout"
          >Checkout</a
        >
        <a v-if="cart.items.length == 0" class="next" href="/">Back to Shop</a>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyMoney from '../shopify/ShopifyMoney.vue'
export default {
  components: { CommonPage, ShopifyImage, ShopifyMoney },
  computed: {
    count() {
      return this.$store.getters['cart/getItemCount']
    },
    cart() {
      return this.$store.state.cart.cart
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-cart {
  background: var(--color-background) 2;
  display: flex;
  justify-content: space-between;
  // align-items:center;
  flex-direction: column;
  padding: 160px 0 0 0;
  min-height: 100vh;

  .heading {
    padding: 0 var(--inset);
    .title {
      @include offset(2);
      @include cx-xl();
      @include cx-uc();
      padding: 0 var(--gutter) 60px;
    }
  }

  .items {
    flex-grow: 1;
  }
  .items-inner {
    @include clearfix();
    .item {
      padding: 0 var(--inset);
      position: relative;
      @include clearfix();
      .item-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
      }
      .item-image,
      .item-title,
      .item-price,
      .item-controls {
        @include span(2);
        @include offset(2);
        @include cx-s();
      }
      .item-title,
      .item-price {
        @include cx-uc();
      }
      .item-image {
        img {
          mix-blend-mode: multiply;
        }
      }
      .item-title {
        @include span(4);
      }
      .item-total {
        @include span(4);
      }

      .item-controls {
        @include span(6);
        @include helper-link-underline(true);
      }

      &:nth-child(1) {
        border-top: 1px solid var(--foreground);
      }

      .item-decorator {
        &:before {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: var(--foreground);
        }
      }
      @include respond-to(xl) {
        .item-image,
        .item-title,
        .item-price,
        .item-controls {
          @include span(3);
          @include offset(1);
        }
      }
      @include respond-to(m) {
        .item-image,
        .item-price {
          @include span(4);
          @include offset(0);
        }
        .item-title {
          @include span(6);
        }
        .item-total {
          @include span(4);
        }

        .item-controls {
          @include span(4);
        }
      }
      @include respond-to(s) {
        .item-inner {
          // flex-wrap: wrap;
        }

        .item-image {
          display: none;
        }
        .item-title {
          @include span(10);
        }
        .item-price {
          @include span(10);
        }
        .item-controls {
          @include span(4);
        }
      }
      @include respond-to(xs) {
        .item-inner {
          flex-wrap: wrap;
        }

        .item-title {
          margin: 0;
          @include span(24);
          padding-bottom: 20px;
        }
        .item-price,
        .item-controls {
          margin: 0;
          @include span(12);
        }
      }
    }
  }
  .empty {
    padding: 0 var(--inset);
    @include cx-l();
    .empty-inner {
      @include cx-l();
      @include offset(2);
      padding: 0 var(--gutter);
    }
  }

  .controls {
    margin-top: -1px;
    padding: 10px var(--inset);
    border-top: 1px solid var(--foreground);
    @include clearfix();
    position: relative;
    flex-align: end;
    .controls-inner {
      position: relative;
      @include cx-uc();
      @include cx-l();
      line-height: 49px;
    }
    .total {
    }
    .next {
      float: right;
      a {
        &:hover {
        }
      }
    }
  }
}
</style>