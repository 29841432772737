<template>
  <footer v-if="hasFooter" class="the-footer">
    <div class="the-footer__inner">
      <!-- Social menu -->
      <CommonLinks
        class="the-footer__social"
        :links="shopify.menus['footer-social'].links"
      />
      <FooterNewsletter
        class="the-footer__newsletter"
        :note="shopify.settings.footer_newsletter_caption"
      />

      <!-- Site By -->
      <nav class="the-footer__credit">
        <a href="http://1of1studio.com" target="_blank">Site by 1/1</a>
      </nav>
    </div>
  </footer>
</template>

<script>
import Vuex from 'vuex'
import CommonLinks from './common/CommonLinks.vue'
import FooterNewsletter from './footer/FooterNewsletter.vue'

export default {
  computed: {
    ...Vuex.mapState({
      shopify: (state) => state.shopify,
    }),
    hasFooter() {
      if (this.shopify.template == 'product') return false
      if (this.shopify.template == 'cart') return false
      return true
    },
  },
  components: { CommonLinks, FooterNewsletter },
}
</script>

<style lang="scss">
@import '~/styles/base';

.the-footer {
  border-top: 1px solid var(--foreground);
  background: var(--color-background);

  &__social {
    display: flex;
    gap: 16px;
  }

  @include respond-to('s+') {
    &__inner {
      @include wrapper;
      display: flex;
      justify-content: space-between;
      padding: 10px 0 10px;
    }
  }

  @include respond-to('s') {
    &__inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding-bottom: 40px;
    }

    &__newsletter {
      border-bottom: 1px solid var(--foreground);
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      padding-bottom: 20px;
      padding: 10px var(--inset);
    }

    &__social {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      padding-left: var(--inset);
    }

    &__credit {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      padding-right: var(--inset);
      text-align: right;
    }
  }
}
</style>