<template>
  <ObserveResize
    class="header-desktop"
    @resize="(event) => event.height > 0 && $emit('resize', event)"
  >
    <OverlayBanner />
    <div class="header-desktop__background">
      <div class="header-desktop__inner">
        <a href="/" class="header-desktop__logo">
          <SvgLogo />
        </a>

        <CommonLinks
          class="header-desktop__menu header-desktop__menu--main"
          :links="shopify.menus['header-main-2'].links"
        />

        <div class="header-desktop__menu header-desktop__menu--account">
          <span class="common-link__wrapper common-links__link common-link--common-link-submenu">
            <CommonLinkSearch :link="{ url: '#cart' }" class="common-links__link" />
          </span>
          <span class="common-link__wrapper common-links__link common-link--common-link-submenu">
            <CommonLinkCurrency :currencies="currencies" class="common-links__link" />
          </span>
          <span class="common-link__wrapper common-links__link common-link--common-link-submenu">
            <CommonLinkCart :link="{ url: '#cart' }" class="common-links__link" />
          </span>
        </div>
      </div>

      <HeaderSubmenus class="header-desktop__submenus" />
    </div>
  </ObserveResize>
</template>

<script>
import SvgLogo from '../svg/SvgLogo.vue'
import CommonLinks from '../common/CommonLinks.vue'
import CommonLinkCart from '../common/CommonLinkCart.vue'
import CommonLinkSearch from '../common/CommonLinkSearch.vue'
import CommonLinkCurrency from '../common/CommonLinkCurrency.vue'
import OverlayBanner from '../overlay/OverlayBanner.vue'
import ObserveResize from '../observe/ObserveResize.vue'
import HeaderSubmenus from './HeaderSubmenus.vue'

export default {
  components: {
    SvgLogo,
    CommonLinks,
    OverlayBanner,
    ObserveResize,
    HeaderSubmenus,
    CommonLinkCart,
    CommonLinkSearch,
    CommonLinkCurrency
  },
  props: {
    currencies: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    shopify() {
      return this.$store.state.shopify
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.header-desktop {
  position: relative;

  &__background {
    background: var(--background);
    /* transition: background-color var(--easing-time) var(--easing); */
  }

  &__inner {
    @include wrapper;
    padding-top: 9px;
    padding-bottom: 7px;
    display: grid;
    grid-template-columns: columns(4) columns(14) columns(6);
    gap: var(--gutter);

    @include respond-to('xl') {
      grid-template-columns: 180px 1fr 240px;
    }
  }

  &__logo {
    width: 140px;
    display: block;
  }

  &__menu {
    display: flex;
    gap: 16px;

    &--account {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;

      > *:last-child {
        display: flex;
        > * {
          margin-left: auto;
        }
      }
    }

    .common-link {
      @include hover {
        color: var(--color-midgrey);
      }
      &--active {
        color: var(--color-midgrey);
      }
    }
  }

  &__submenus {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}
</style>