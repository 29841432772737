<template>
  <section v-if="active" ref="grid" class="reference-grid">
    <div class="reference-grid__columns">
      <div
        v-for="(v, k) in getColumns()"
        :key="`grid_column_${k}`"
        class="reference-grid__column"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      columns: 0,
      inset: 0,
      gutter: 0,
    }
  },
  mounted() {
    // Persistence
    this.active = localStorage.getItem('showGrid') == 'true'

    // Listen
    window.document.addEventListener('keydown', (e) => this.onKeyDown(e))

    // Get CSS vars
    this.$nextTick(() => {
      const computedStyle = window.getComputedStyle(this.$parent.$el)
      this.columns = parseInt(computedStyle.getPropertyValue('--columns') || 0)
      this.inset = parseInt(computedStyle.getPropertyValue('--inset') || 0)
      this.gutter = parseInt(
        computedStyle.getPropertyValue('--gutter') / 2 || 0
      )
    })
  },
  methods: {
    toggleActive() {
      this.active = !this.active
      localStorage.setItem('showGrid', this.active)
    },
    onKeyDown(event) {
      if (
        event.altKey &&
        event.ctrlKey &&
        event.shiftKey &&
        event.keyCode === 71 // 'G'
      )
        this.toggleActive()
    },
    getColumns() {
      const count = this.columns
      return Array(count).fill(count)
    },
  },
}
</script>

<style lang="scss">
.reference-grid {
  position: fixed;
  top: 0;
  height: 100%;
  left: var(--inset);
  width: var(--wrapper);
  z-index: 9999;
  display: flex;
  pointer-events: none;

  &__columns {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    column-gap: var(--gutter);
  }

  &__column {
    position: relative;
    display: flex;
    opacity: 0.2;
    background: red;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-right: red;
    }
  }
}
</style>
