<template>
  <nav v-if="links" :class="getClass">
    <CommonLink
      v-for="(link, index) in links"
      :key="getKey('CommonLinks__link', index)"
      :link="link"
      class="common-links__link"
    />
  </nav>
</template>

<script>
import CommonLink from './CommonLink.vue'

export default {
  name: 'CommonLinks',
  components: {
    CommonLink,
  },
  props: {
    links: Array,
    mode: {
      default: 'default',
    },
  },
  computed: {
    getClass() {
      return `common-links common-links--${this.mode}`
    },
  },
}
</script>

<style lang="scss">
.common-links {
  &--column {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 32px;
    /* gap: 4px; */

    .common-link {
      position: relative;
      &:before {
        content: ' ';
        position: absolute;
        display: block;
        width: 8px;
        height: 1px;
        right: calc(100% + 12px);
        bottom: 9px;
        background: var(--foreground);
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      &:hover,
      &--active {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}
</style>