<template>
  <div class="icon-cart">
    <div class="icon-cart__count">
      {{ count }}
    </div>
    <SvgCart class="icon-cart__shape" />
  </div>
</template>
<script>
import SvgCart from '../svg/SvgCart.vue'
export default {
  components: { SvgCart },
  computed: {
    count() {
      return this.$store.getters['cart/getItemCount']
    },
  },
}
</script>

<style lang="scss">
.icon-cart {
  display: grid;

  &__count,
  &__shape {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  &__count {
    padding-top: 8px;
  }
}
</style>