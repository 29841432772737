<template>
  <section v-if="content" class="article-rows" v-html="content"></section>
</template>

<script>
export default {
  props: {
    content: String,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.article-rows {
  margin-bottom: 130px;
  padding: 0 var(--inset);
  .row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
    .image {
      flex: 1;
      display: flex;
      align-items: flex-end;
      /* padding: 0 var(--gutter); */
      
    }
    .text {
      width: columns(16);
      padding: 0 columns(1) 60px;
      font-size: 16px;
      line-height: 1.2;
      p {
        max-width: 500px;
        &:before {
          content: '\201C';
          margin-left: -10px;
        }
        &:after {
          content: '\201D';
          margin-right: -10px;
        }
      }
      &.small {
        width: columns(8);
      }
      &.large {
        font-size: 28px;
      }
      cite {
        display: block;
        margin-top: 2em;
        text-align: right;
        /* @include cx(s); */
        &:before {
          content: '— ';
        }
      }
    }
    &:nth-child(2n + 2) {
      .image {
        order: 1;
      }
    }
  }
  @include respond-to(m) {
    margin-top: 40px;
    .row {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
      .image {
        &:nth-child(2) {
          margin-top: 40px;
          margin-left: 0;
        }
      }
      .text {
        width: auto;
        padding: 25px var(--gutter);
        &.small {
          width: auto;
        }
        p {
          padding: 60px 0 60px;
        }
      }
      &:nth-child(2n + 2) {
        .image {
          order: 0;
        }
      }
    }
  }


  .journal-row {
    align-items: flex-end;
    display: flex;
    margin-bottom: 120px;

    &:nth-child(2n + 2) {
      justify-content: flex-end;
    }

    .journal-row__image {
      padding: 0 5px;
      width: 50%;

      &.journal-row__image_small {
        width: 33.3333333333%;
      }

      &.journal-row__image_large {
        width: 66.6666666667%;
      }
    }

    .journal-row__text {
      flex: 1;
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 80px;
      max-width: 420px;
      padding: 0 40px;

      &.journal-row__text_large {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 800px) {
    .journal-row {
      align-items: flex-start;
      flex-direction: column;

      &:nth-child(2n + 2) {
        justify-content: flex-start;
      }
    }

    .journal-row__image,
    .journal-row__image_large,
    .journal-row__image_small {
      width: 100%;
    }

    .journal-row__text {
      margin-bottom: 0;
      margin-top: 40px;
      order: 1;
      padding: 0 5px;
    }
  }

}
</style>