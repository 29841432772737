const DEFAULT_CURRENCY = window.Shopify.currency.active || 'NZD'

export const state = () => ({
  current: DEFAULT_CURRENCY,
})

export const actions = {}

export const mutations = {
  set(state, d) {
    state.current = d
  },
}

export const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
