
const hasMouse = window.matchMedia("(any-pointer: fine)").matches

export const state = () => ({
  hasMouse,
  backgroundColor: 'var(--color-background)',
})

export const mutations = {
  setBackground(state, value) {
    state.backgroundColor = value;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
}