
import Vue from 'vue'
import Vuex from 'vuex'

import app from './app';
import currency from './currency';
import shopify from './shopify';
import product from './product';
import cart from './cart';
import overlay from './overlay';
import submenu from './submenu';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  modules: {
    app,
    currency,
    product,
    shopify,
    cart,
    overlay,
    submenu,
  },
  plugins: [
    
  ],
})
