export const state = () => ({
  ...window._data
});

export const actions = {
};

export const mutations = {
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
