<template>
  <TransitionHeight>
    <ObserveResize
      v-if="active"
      class="overlay-banner"
      @resize="(event) => $emit('resize', event)"
    >
      <div class="overlay-banner__inner">
        <div class="overlay-banner__content" v-html="content"></div>
        <div class="overlay-banner__close">
          <button class="a" @click="close">Close</button>
        </div>
      </div>
    </ObserveResize>
  </TransitionHeight>
</template>

<script>
import Vuex from 'vuex'
import ObserveResize from '../observe/ObserveResize.vue'
import TransitionHeight from '../transition/TransitionHeight.vue'

export default {
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...Vuex.mapState({
      content: (state) => state.shopify.settings.banner_description,
    }),
  },
  mounted() {
    const storage = window.localStorage.getItem('banner_overlay')
    const hide_time = !storage ? false : storage

    const oldContent = window.localStorage.getItem('banner_overlay_content')
    const hasChanged = this.content !== oldContent

    // Don't show if shown in last X time
    if (!!hide_time) {
      var current_time = Date.now()
      var days = 1000 * 60 * 60 * 24
      var time_ago = Math.round((current_time - hide_time) / days)
      if (time_ago < 14 && !hasChanged) return
    }

    setTimeout(() => {
      if (this.content == '') return
      // if (window._data.logged_in) return;

      window.localStorage.setItem('banner_overlay_content', this.content)

      //Finally, show
      this.active = true
    }, 1000)
  },
  methods: {
    close() {
      window.localStorage.setItem('banner_overlay', Date.now())
      this.active = false
    },
  },
  components: {
    ObserveResize,
    TransitionHeight,
  },
}
</script>


<style lang="scss">
@import '~/styles/base.scss';

.overlay-banner {
  --foreground: var(--color-dark);
  @include cx-s;
  @include sans;
  transition: all 0.4s ease;
  overflow: hidden;
  border-bottom: 1px solid var(--foreground);
  height: auto;
  background: #f5f4e8;

  &--active {
    opacity: 1;
    max-height: 100px;
  }

  &__inner {
    line-height: 16px;
    display: grid;
    grid-template-columns: 1fr 60px;
    @include wrapper;
    padding: 9px 0 7px 60px;
  }

  &__content {
    text-align: center;
  }

  &__close {
    cursor: pointer;
    display: flex;
    @include helper-link-underline(1);

    > .a {
      display: block;
      margin-left: auto;
    }
  }
}
</style>