import { AxiosGet, AxiosPost } from './client';

export async function get() {
  return await AxiosGet(`/cart.json`);
}

export async function add(data) {
  return await AxiosPost(`/cart/add.js`, { data });
}

export async function remove(data) {
  return await AxiosPost(`/cart/update.js`, { data });
}

export async function update(data) {
  return await AxiosPost(`/cart/update.js`, { data });
}

export async function clear(data) {
  return await AxiosPost(`/cart/clear.js`, { data });
}
